import getConfig from 'next/config';

import View, { ViewVariants } from '../src/common/View';
import StandardHeader, {
  StandardHeaderVariants,
} from '../src/modules/StandardHeader';
import AboutList from '../src/modules/AboutList';

import PartnerHead from '../src/modules/PartnerHead';
import useCopy, { ABOUT_KEY } from '../src/hooks/useCopy';

const { publicRuntimeConfig: config } = getConfig();

const Index = () => {
  const { ABOUT_DESCRIPTION, ABOUT_TITLE } = useCopy(ABOUT_KEY, {
    country: config.partnerCountry,
  });

  return (
    <>
      <PartnerHead />
      <main>
        <View variant={ViewVariants.about}>
          <View.Header>
            <StandardHeader
              variant={StandardHeaderVariants.CENTERED}
              title={ABOUT_TITLE}
              description={ABOUT_DESCRIPTION}
            />
          </View.Header>
          <View.Body>
            <View.Column>
              <AboutList />
            </View.Column>
          </View.Body>
        </View>
      </main>
    </>
  );
};

export const getServerSideProps = async () => {
  return {
    props: {},
  };
};

export default Index;
