import useTranslation from 'next-translate/useTranslation';
import * as constants from '../../locales/constants';

const useCopy = (key, data) => {
  const { t } = useTranslation('index');
  return t(key, data, {
    returnObjects: true,
  });
};

export const COMMON_KEY = constants.COMMON_KEY;
export const NOT_FOUND_KEY = constants.NOT_FOUND_KEY;
export const ABOUT_KEY = constants.ABOUT_KEY;
export const ACCOUNT_DETAILS_KEY = constants.ACCOUNT_DETAILS_KEY;
export const SEND_AMOUNT_KEY = constants.SEND_AMOUNT_KEY;
export const UPDATE_PHONE_KEY = constants.UPDATE_PHONE_KEY;
export const TRANSACTION_SUMMARY_KEY = constants.TRANSACTION_SUMMARY_KEY;
export const DETAILS_KEY = constants.DETAILS_KEY;
export const EMAIL_VERIFICATION_KEY = constants.EMAIL_VERIFICATION_KEY;
export const IDV_DESKTOP_KEY = constants.IDV_DESKTOP_KEY;
export const ERRORS_KEY = constants.ERRORS_KEY;
export const ABOUT_LIST_KEY = constants.ABOUT_LIST_KEY;
export const BRAND_HEADER_KEY = constants.BRAND_HEADER_KEY;
export const PAYMENT_KEY = constants.PAYMENT_KEY;
export const PHONE_VERIFICATION_KEY = constants.PHONE_VERIFICATION_KEY;
export const TRANSFER_SUCCESS_KEY = constants.TRANSFER_SUCCESS_KEY;
export const SECURE_PAYMENT_KEY = constants.SECURE_PAYMENT_KEY;
export const RECEIPT_FOOTER_KEY = constants.RECEIPT_FOOTER_KEY;
export const RECEIPT_KEY = constants.RECEIPT_KEY;
export const KYC_FAIL_HEADER_KEY = constants.KYC_FAIL_HEADER_KEY;
export const PERSONAL_DETAILS_KEY = constants.PERSONAL_DETAILS_KEY;
export const SUMMARY_KEY = constants.SUMMARY_KEY;
export const VERIFICATION_KEY = constants.VERIFICATION_KEY;

export default useCopy;
