/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import toImage from '../../../../utils/toImage';
import styles from './index.module.scss';

const AboutCard = ({ number, title, imgSrc }) => {
  const panelRef = useRef();
  useEffect(() => {
    const animationTrigger = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.animation);
        }
      });
    };
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3,
    };
    const observer = new IntersectionObserver(animationTrigger, options);

    observer.observe(panelRef.current);
  }, []);
  return (
    <div ref={panelRef} className={styles.container}>
      <div className={styles.divider}>
        <div className={styles.line} />
        <div className={styles.indexContainer}>
          <p className={styles.index}>{number}</p>
        </div>
      </div>
      <p className={styles.title}>{title}</p>
      <img className={styles.image} alt="card-img" src={toImage(imgSrc)}></img>
    </div>
  );
};

AboutCard.defaultProps = {
  index: 0,
  title: '',
  imgSrc: '',
};

AboutCard.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
};

export default AboutCard;
