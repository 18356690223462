import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import lottie from 'lottie-web';
import PropTypes from 'prop-types';

import lottieSpinner from '../../../assets/lottie/spinner.json';
import styles from './View.module.scss';

export const ViewVariants = {
  default: 'default',
  about: 'about',
};

export const ViewColumnVariants = {
  primary: 'primary',
  secondary: 'secondary',
};

const ViewLoader = ({ text }) => {
  const spinner = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: spinner.current,
      animationData: lottieSpinner,
    });
  }, []);
  return (
    <div className={styles.loader}>
      <div className={styles.loaderBackground} />
      <div className={styles.spinnerContainer}>
        <div ref={spinner} id="lottie-spinner" />
        <p className={styles.loaderText}>{text}</p>
      </div>
    </div>
  );
};

const ViewColumn = ({ children, variant }) => {
  const columnClass = cn(styles.column, {
    [styles.columnPrimary]: variant == ViewColumnVariants.primary,
    [styles.columnSecondary]: variant == ViewColumnVariants.secondary,
  });
  return <div className={columnClass}>{children}</div>;
};

ViewColumn.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ViewColumn.defaultProps = {
  variant: ViewColumnVariants.primary,
};

const ViewHeader = ({ children }) => (
  <header className={styles.header}>
    <div className={styles.container}>{children}</div>
  </header>
);

ViewHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const ViewBody = ({ children }) => (
  <div className={styles.body}>
    <div className={cn(styles.container, styles.containerBody)}>{children}</div>
  </div>
);

ViewBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const ViewFooter = ({ children }) => (
  <div className={styles.footer}>
    <div className={cn(styles.container, styles.containerBody)}>{children}</div>
  </div>
);

ViewFooter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const View = ({ children, loading, loaderText, variant }) => {
  const baseClass = cn(styles.base, {
    [styles[variant]]: variant,
  });
  return (
    <section className={baseClass}>
      {children}
      {loading && <ViewLoader text={loaderText} />}
    </section>
  );
};

View.Header = ViewHeader;
View.Body = ViewBody;
View.Column = ViewColumn;
View.Loader = ViewLoader;
View.Footer = ViewFooter;

View.propTypes = {
  loaderText: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default View;
