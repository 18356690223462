import getConfig from 'next/config';
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useRouter } from 'next/router';
import setLanguage from 'next-translate/setLanguage';

import LanguageList from '../../common/LanguageList';
import HeaderName from '../../common/HeaderName';
import Brand from '../../common/Brand';
import toImage from '../../utils/toImage';
import partnerLogo from '../../../assets/logos/partner.svg';
import { useChangeLocale } from '../../contexts/i18n';

import { useApp } from '../../contexts/appContext/AppContext';
import styles from './index.module.scss';

const { publicRuntimeConfig: config } = getConfig();

export const StandardHeaderVariants = {
  DEFAULT: 'default',
  NARROW: 'narrow',
  CENTERED: 'centered',
};

const StandardHeader = ({ title, description, back, variant, logo }) => {
  const { changeLocale, locale, locales } = useChangeLocale();

  const { updatePreferredLang } = useApp();

  const base = cn({
    [styles.default]: variant === StandardHeaderVariants.DEFAULT,
    [styles.narrow]: variant === StandardHeaderVariants.NARROW,
    [styles.centered]: variant === StandardHeaderVariants.CENTERED,
  });
  return (
    <>
      <HeaderName
        name=""
        className={base}
        brand={
          <Brand
            src={logo ? toImage(logo) : toImage(partnerLogo)}
            label={config.partnerName}
          />
        }
        title={title}
        lead={description}
        back={back}
      />
      {locales.length > 1 ? (
        <div className={styles.langContainer}>
          <LanguageList
            languageOptions={locales}
            selectedLanguage={locale}
            onClick={(v) => {
              updatePreferredLang(v);
              changeLocale(v);
            }}
          />
        </div>
      ) : null}
    </>
  );
};

StandardHeader.defaultProps = {
  variant: StandardHeaderVariants.DEFAULT,
};

StandardHeader.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  back: PropTypes.node,
};

export default StandardHeader;
