/* eslint-disable @next/next/no-img-element */
import React from 'react';
import getConfig from 'next/config';

import iPhoneSender from '../../../assets/images/iPhone-sender.svg';
import chat from '../../../assets/images/chat.svg';
import sendTransfers from '../../../assets/images/send-transfers.svg';

import useCopy, { ABOUT_LIST_KEY } from '../../hooks/useCopy';

import AboutPanel from './components/AboutPanel';

const { publicRuntimeConfig: config } = getConfig();

const AboutList = () => {
  const { ABOUT_LIST_ASK, ABOUT_LIST_CHOOSE, ABOUT_LIST_INSTANT } = useCopy(
    ABOUT_LIST_KEY,
    {
      partner: config.partnerName,
    }
  );
  const List = [
    {
      title: ABOUT_LIST_ASK,
      imgSrc: iPhoneSender,
    },
    {
      title: ABOUT_LIST_CHOOSE,
      imgSrc: chat,
    },
    {
      title: ABOUT_LIST_INSTANT,
      imgSrc: sendTransfers,
    },
  ];
  return List.map((item, index) => (
    <AboutPanel
      title={item.title}
      key={item.title}
      number={index + 1}
      imgSrc={item.imgSrc}
    />
  ));
};

AboutList.defaultProps = {};

AboutList.propTypes = {};

export default AboutList;
