/* eslint-disable @next/next/no-img-element */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './Brand.module.scss';

const Brand = ({ label, src, width, height }) => {
  return (
    <>
      <img className={styles.img} src={src} alt="logo" />
      <span className={styles.label}>{label}</span>
    </>
  );
};

Brand.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  label: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

Brand.defaultProps = {
  width: 90,
  height: 40,
};

export default Brand;
