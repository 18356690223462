/* eslint-disable react/jsx-key */
import getConfig from 'next/config';
import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

const { publicRuntimeConfig: config } = getConfig();

const PartnerHead = ({
  title,
  content,
  domain,
  url,
  image,
  imageType,
  imageWidth,
  imageHeight,
  tags,
}) => (
  <Head>
    {[
      <title key="title:main">{title}</title>,
      <meta key="title" name="title" content={title} />,
      <meta key="name" name="description" content={content} />,

      <meta key="og:type" property="og:type" content="website" />,
      <meta key="og:title" property="og:title" content={title} />,
      <meta key="og:description" property="og:description" content={content} />,
      <meta key="og:image" property="og:image" content={image} />,
      <meta
        key="og:image:secure_url"
        property="og:image:secure_url"
        content={image}
      />,
      <meta key="og:image:type" property="og:image:type" content={imageType} />,
      <meta
        key="og:image:width"
        property="og:image:width"
        content={imageWidth}
      />,
      <meta
        key="og:image:height"
        property="og:image:height"
        content={imageHeight}
      />,
      <meta key="og:image:alt" property="og:image:alt" content={content} />,
      <meta key="og:url" property="og:url" content={url} />,

      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />,
      <meta key="twitter:domain" name="twitter:domain" content={domain} />,
      <meta key="twitter:url" name="twitter:url" content={url} />,
      <meta key="twitter:title" name="twitter:title" content={title} />,
      <meta
        key="twitter:description"
        name="twitter:description"
        content={content}
      />,
      <meta key="twitter:image" name="twitter:image" content={image} />,
      <meta key="twitter:image:src" name="twitter:image:src" content={image} />,

      <link key="icon" rel="icon" href="/favicon.ico" />,
      ...tags,
    ]}
  </Head>
);

PartnerHead.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  domain: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  imageType: PropTypes.string,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.element),
};

PartnerHead.defaultProps = {
  title: config.partnerMetaTitle,
  content: `${config.partnerMetaContent}${config.partnerCountry}`,
  domain: config.partnerMetaDomain,
  url: config.partnerMetaUrl,
  image: config.partnerMetaImage
    ? config.partnerMetaImage
        .replace('{CC}', config.partnerCountryAbbr.toLowerCase())
        .replace('{ENV}', 'preview')
    : '',
  imageType: config.partnerMetaImageType || 'image/png',
  imageWidth: config.partnerMetaImageWidth || '300',
  imageHeight: config.partnerMetaImageHeight || '200',
  tags: [],
};

export default PartnerHead;
