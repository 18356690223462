import cn from 'classnames';

import styles from './index.module.scss';

const LanguageListItem = ({ onClick, language, selectedLanguage }) => {
  const languageClass = cn({
    [styles.option]: true,
    [styles.selected]: selectedLanguage === language,
  });
  return (
    <a onClick={() => onClick(language)} className={languageClass}>
      <p className={styles.language}>{language}</p>
    </a>
  );
};

const LanguageList = ({ languageOptions, selectedLanguage, onClick }) => (
  <div className={styles.container}>
    {languageOptions.map((language, idx) => (
      <div className={styles.language} key={language}>
        {idx !== 0 && <span className={styles.seperator}>|</span>}
        <LanguageListItem
          language={language}
          onClick={onClick}
          selectedLanguage={selectedLanguage}
        />
      </div>
    ))}
  </div>
);

export default LanguageList;
