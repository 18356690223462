import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './HeaderName.module.scss';

const HeaderName = ({
  brand,
  name,
  title,
  lead,
  animate,
  back,
  languageList,
}) => {
  const highlightClass = cn({
    [styles.highlight]: true,
    [styles.animation]: animate,
    [styles.static]: !animate,
  });
  // TODO -- need to rethink name accent w/full name
  const firstName = name.split(' ')[0];
  return (
    <div className={styles.base}>
      <>{languageList}</>
      <div className={styles.brand}>{brand}</div>
      <div>
        {back && <div className={styles.back}>{back}</div>}
        <h1 className={styles.title}>
          {title}
          {name && (
            <span className={styles.nameContainer}>
              <span className={highlightClass} />
              <span className={styles.name}>You!</span>
            </span>
          )}
        </h1>
        <p>{lead}</p>
      </div>
    </div>
  );
};

HeaderName.propTypes = {
  brand: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  animate: PropTypes.bool,
};

export default HeaderName;
